@media screen and (max-width: 1023px) {
    .topHeader {
      position: fixed;
      width: 100%;
      z-index: 999;
    }
    .topHeaderTop {
      height: 40px !important;
      padding: 0 42px !important;
      line-height: 39px !important;
      background: rgba(0, 0, 0, 0.6) !important;
    }
    .moreHeaderTop {
      height: 40px !important;
      padding: 0 42px !important;
      line-height: 39px !important;
      background: rgba(247, 206, 206, 0.9) !important;
    }
    .logo {
      float: left;
      width: 225px;
      height: auto;
      margin-right: 6px;
      margin-left: -15px;
      /* cursor: pointer; */
    }
    .headerFunctional {
      float: right;
      width: 114px;
    }

    .userImg {
      float: left;
      width: 20px;
      height: 20px;
      margin-top: 10px;
      border-radius: 20px;
      overflow: hidden;
    }
    .menuStyle {
      float: left;
      width: 385px;
    }
  
    .verticalSplit {
      display: inline-block;
      width: 0px;
      height: 13px;
      border: 0px solid #c4c4c4;
      opacity: 0.7;
      position: relative;
      top: 4px;
    }
  
    /*  重写antd样式*/
  
    .headMenu > ul > li > span > a {
      color: #ffffff !important;
    }
    .headMenu > ul > li {
      padding: 0 8px !important;
    }
    .App .ant-menu {
      font-size: 7px !important;
      background-color: transparent !important;
      color: #fff !important;
    }
    .userName {
      position: relative;
      font-size: 7px;
      float: left;
      color: #fff;
      display: inline-flex;
    }
    .userName p {
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 74px;
      text-align: center;
    }
    .userName span {
      margin-top: 15px;
    }
    .ant-menu-horizontal {
      border-bottom: transparent !important;
    }
  
    .ant-btn {
      padding: 0 !important;
      font-size: 7px !important;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1151px) {
    .topHeader {
      position: fixed;
      width: 100%;
      z-index: 999;
    }
    .topHeaderTop {
      height: 51px !important;
      padding: 0 53px !important;
      line-height: 50px !important;
      background: rgba(0, 0, 0, 0.6) !important;
    }
    .moreHeaderTop {
      height: 51px !important;
      padding: 0 53px !important;
      line-height: 50px !important;
      background: rgba(63, 63, 63, 0.9) !important;
    }
    .logo {
      float: left;
      width: 288px;
      height: auto;
      margin-right: 8px;
      margin-left: -19px;
      /* cursor: pointer; */
    }
    .headerFunctional {
      float: right;
      width: 146px;
    }
  
    .userImg {
      float: left;
      width: 25.5px;
      height: 25.5px;
      margin-top: 12.75px;
      border-radius: 26px;
      overflow: hidden;
    }
    .menuStyle {
      float: left;
      width: 493px;
    }
  
    .verticalSplit {
      display: inline-block;
      width: 0px;
      height: 16px;
      border: 1px solid #c4c4c4;
      opacity: 0.7;
      position: relative;
      top: 5px;
    }
  
    /*  重写antd样式*/
  
    .headMenu > ul > li > span > a {
      color: #ffffff !important;
    }
    .headMenu > ul > li {
      padding: 0 11px !important;
    }
    .App .ant-menu {
      font-size: 9px !important;
      background-color: transparent !important;
      color: #fff !important;
    }
    .userName {
      position: relative;
      font-size: 9px;
      float: left;
      color: #fff;
      display: inline-flex;
    }
    .ant-menu-horizontal {
      border-bottom: transparent !important;
    }
    .userName p {
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 110.5px;
      text-align: center;
    }
    .userName span {
      margin-top: 19.75px;
    }
    .ant-btn {
      padding: 0 !important;
      font-size: 9px !important;
    }
  }
  @media screen and (min-width: 1152px) and (max-width: 1279px) {
    .topHeader {
      position: fixed;
      width: 100%;
      z-index: 999;
    }
    .topHeaderTop {
      height: 58px !important;
      padding: 0 60px !important;
      line-height: 56px !important;
      background: rgba(0, 0, 0, 0.6) !important;
    }
    .moreHeaderTop {
      height: 58px !important;
      padding: 0 60px !important;
      line-height: 56px !important;
      background: rgba(63, 63, 63, 0.9) !important;
    }
    .logo {
      float: left;
      width: 324px;
      height: auto;
      margin-right: 9px;
      margin-left: -21px;
      /* cursor: pointer; */
    }
    .headerFunctional {
      float: right;
      width: 164px;
    }
  
    .userImg {
      float: left;
      width: 29px;
      height: 29px;
      margin-top: 14.5px;
      border-radius: 29px;
      overflow: hidden;
    }
    .menuStyle {
      float: left;
      width: 555px;
    }
  
    .verticalSplit {
      display: inline-block;
      width: 0px;
      height: 18px;
      border: 1px solid #c4c4c4;
      opacity: 0.7;
      position: relative;
      top: 6px;
    }
  
    /*  重写antd样式*/
  
    .headMenu > ul > li > span > a {
      color: #ffffff !important;
    }
    .headMenu > ul > li {
      padding: 0 12px !important;
    }
    .App .ant-menu {
      font-size: 10px !important;
      background-color: transparent !important;
      color: #fff !important;
    }
    .userName {
      position: relative;
      font-size: 10px;
      float: left;
      color: #fff;
      display: inline-flex;
    }
    .ant-menu-horizontal {
      border-bottom: transparent !important;
    }
  
    .userName p {
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 125px;
      text-align: center;
    }
    .userName span {
      margin-top: 21.5px;
    }
    .ant-btn {
      padding: 0 !important;
      font-size: 10px !important;
    }
  }
  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    .topHeader {
      position: fixed;
      width: 100%;
      z-index: 999;
    }
    .topHeaderTop {
      height: 64px !important;
      padding: 0 67px !important;
      line-height: 63px !important;
      background: rgba(0, 0, 0, 0.6) !important;
    }
    .moreHeaderTop {
      height: 64px !important;
      padding: 0 67px !important;
      line-height: 63px !important;
      background: rgba(63, 63, 63, 0.9) !important;
    }
    .logo {
      float: left;
      width: 360px;
      height: auto;
      margin-right: 10px;
      margin-left: -23px;
      /* cursor: pointer; */
    }
    .headerFunctional {
      float: right;
      width: 183px;
    }

    .userImg {
      float: left;
      width: 32px;
      height: 32px;
      margin-top: 16px;
      border-radius: 32px;
      overflow: hidden;
    }
  
    .menuStyle {
      float: left;
      width: 617px;
    }
  
    .verticalSplit {
      display: inline-block;
      width: 0px;
      height: 20px;
      border: 1px solid #c4c4c4;
      opacity: 0.7;
      position: relative;
      top: 7px;
    }
  
    /*  重写antd样式*/
  
    .headMenu > ul > li > span > a {
      color: #ffffff !important;
    }
    .headMenu > ul > li {
      padding: 0 13px !important;
    }
    .App .ant-menu {
      font-size: 11px !important;
      background-color: transparent !important;
      color: #fff !important;
    }
    .userName {
      position: relative;
      font-size: 11px;
      float: left;
      color: #fff;
      display: inline-flex;
    }
    .ant-menu-horizontal {
      border-bottom: transparent !important;
    }
  
    .userName p {
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 141px;
      text-align: center;
    }
    .userName span {
      margin-top: 24.75px;
    }
    .ant-btn {
      padding: 0 !important;
      font-size: 11px !important;
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1599px) {
    .topHeader {
      position: fixed;
      width: 100%;
      z-index: 999;
    }
    .topHeaderTop {
      height: 72px !important;
      padding: 0 75px !important;
      line-height: 71px !important;
      background: rgba(0, 0, 0, 0.6) !important;
    }
    .moreHeaderTop {
      height: 72px !important;
      padding: 0 75px !important;
      line-height: 71px !important;
      background: rgba(63, 63, 63, 0.9) !important;
    }
    .logo {
      float: left;
      width: 405px;
      height: auto;
      margin-right: 11px;
      margin-left: -26px;
      /* cursor: pointer; */
    }
    .headerFunctional {
      float: right;
      width: 206px;
    }
    .userImg {
      float: left;
      width: 36px;
      height: 36px;
      margin-top: 18px;
      border-radius: 36px;
      overflow: hidden;
    }
  
    .menuStyle {
      float: left;
      width: 694px;
    }
  
    .verticalSplit {
      display: inline-block;
      width: 0px;
      height: 23px;
      border: 1px solid #c4c4c4;
      opacity: 0.7;
      position: relative;
      top: 8px;
    }
  
    /*  重写antd样式*/
  
    .headMenu > ul > li > span > a {
      color: #ffffff !important;
    }
    .headMenu > ul > li {
      padding: 0 15px !important;
    }
    .App .ant-menu {
      font-size: 12px !important;
      background-color: transparent !important;
      color: #fff !important;
    }
    .userName {
      position: relative;
      font-size: 12px;
      float: left;
      color: #fff;
      display: inline-flex;
    }
    .ant-menu-horizontal {
      border-bottom: transparent !important;
    }
  
    .userName p {
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 160px;
      text-align: center;
    }
    .userName span {
      margin-top: 28px;
    }
    .ant-btn {
      padding: 0 !important;
      font-size: 12px !important;
    }
  }
  @media screen and (min-width: 1600px) and (max-width: 1679px) {
    .topHeader {
      position: fixed;
      width: 100%;
      z-index: 999;
    }
    .topHeaderTop {
      height: 80px !important;
      padding: 0 83px !important;
      line-height: 78px !important;
      background: rgba(0, 0, 0, 0.6) !important;
    }
    .moreHeaderTop {
      height: 80px !important;
      padding: 0 83px !important;
      line-height: 78px !important;
      background: rgba(63, 63, 63, 0.9) !important;
    }
    .logo {
      float: left;
      width: 450px;
      height: auto;
      margin-right: 13px;
      margin-left: -29px;
      /* cursor: pointer; */
    }
    .headerFunctional {
      float: right;
      width: 228px;
    }
  
    .userImg {
      float: left;
      width: 40px;
      height: 40px;
      margin-top: 20px;
      border-radius: 40px;
      overflow: hidden;
    }
    .menuStyle {
      float: left;
      width: 771px;
    }
  
    .verticalSplit {
      display: inline-block;
      width: 0px;
      height: 25px;
      border: 1px solid #c4c4c4;
      opacity: 0.7;
      position: relative;
      top: 8px;
    }
  
    /*  重写antd样式*/
  
    .headMenu > ul > li > span > a {
      color: #ffffff !important;
    }
    .headMenu > ul > li {
      padding: 0 17px !important;
    }
    .App .ant-menu {
      font-size: 13px !important;
      background-color: transparent !important;
      color: #fff !important;
    }
    .userName {
      position: relative;
      font-size: 13px;
      float: left;
      color: #fff;
      display: inline-flex;
    }
    .ant-menu-horizontal {
      border-bottom: transparent !important;
    }
  
    .userName p {
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 178px;
      text-align: center;
    }
    .userName span {
      margin-top: 32px;
    }
    .ant-btn {
      padding: 0 !important;
      font-size: 13px !important;
    }
  }
  @media screen and (min-width: 1680px) and (max-width: 1919px) {
    .topHeader {
      position: fixed;
      width: 100%;
      z-index: 999;
    }
    .topHeaderTop {
      height: 84px !important;
      padding: 0 88px !important;
      line-height: 82px !important;
      background: rgba(0, 0, 0, 0.6) !important;
    }
    .moreHeaderTop {
      height: 84px !important;
      padding: 0 88px !important;
      line-height: 82px !important;
      background: rgba(63, 63, 63, 0.9) !important;
    }
    .logo {
      float: left;
      width: 473px;
      height: auto;
      margin-right: 13px;
      margin-left: -31px;
      /* cursor: pointer; */
    }
    .headerFunctional {
      float: right;
      width: 240px;
    }
    .userImg {
      float: left;
      width: 42px;
      height: 42px;
      margin-top: 21px;
      border-radius: 42px;
      overflow: hidden;
    }
  
    .menuStyle {
      float: left;
      width: 809px;
    }
  
    .verticalSplit {
      display: inline-block;
      width: 0px;
      height: 26px;
      border: 1px solid #c4c4c4;
      opacity: 0.7;
      position: relative;
      top: 9px;
    }
  
    /*  重写antd样式*/
  
    .headMenu > ul > li > span > a {
      color: #ffffff !important;
    }
    .headMenu > ul > li {
      padding: 0 18px !important;
    }
    .App .ant-menu {
      font-size: 14px !important;
      background-color: transparent !important;
      color: #fff !important;
    }
    .userName {
      position: relative;
      font-size: 14px;
      float: left;
      color: #fff;
      display: inline-flex;
    }
    .ant-menu-horizontal {
      border-bottom: transparent !important;
    }
  
    .userName p {
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 188px;
      text-align: center;
    }
    .userName span {
      margin-top: 35px;
    }
    .ant-btn {
      padding: 0 !important;
      font-size: 14px !important;
    }
  }
  @media screen and (min-width: 1920px) {
    .topHeader {
      position: fixed;
      width: 100%;
      z-index: 999;
    }
    .topHeaderTop {
      height: 96px !important;
      padding: 0 100px !important;
      line-height: 94px !important;
      background: rgba(0, 0, 0, 0.6) !important;
    }
    .moreHeaderTop {
      height: 96px !important;
      padding: 0 100px !important;
      line-height: 94px !important;
      background: rgba(63, 63, 63, 0.9) !important;
    }
    .logo {
      float: left;
      width: 540px;
      height: auto;
      margin-right: 15px;
      margin-left: -35px;
      /* cursor: pointer; */
    }
    .headerFunctional {
      float: right;
      width: 274px;
    }
    .userImg {
      float: left;
      width: 48px;
      height: 48px;
      margin-top: 24px;
      border-radius: 48px;
      overflow: hidden;
    }
  
    .menuStyle {
      float: left;
      width: 925px;
    }
  
    .verticalSplit {
      display: inline-block;
      width: 0px;
      height: 30px;
      border: 1px solid #c4c4c4;
      opacity: 0.7;
      position: relative;
      top: 10px;
    }
  
    /*  重写antd样式*/
  
    .headMenu > ul > li > span > a {
      color: #ffffff !important;
    }
    .headMenu > ul > li {
      padding: 0 20px !important;
    }
    .App .ant-menu {
      font-size: 16px !important;
      background-color: transparent !important;
      color: #fff !important;
    }
    .userName {
      position: relative;
      font-size: 16px;
      float: left;
      color: #fff;
      display: inline-flex;
    }
    .ant-menu-horizontal {
      border-bottom: transparent !important;
    }
  
    .userName p {
      /* padding-left: 10px; */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      /* width: 216px; */
      text-align: center;
      padding: 0 20px;
    }
    .userName span {
      margin-top: 39px;
    }
    .ant-btn {
      padding: 0 !important;
      font-size: 16px !important;
    }
  }
  
  .ant-menu-vertical-left.ant-menu-sub, .ant-menu-vertical-right.ant-menu-sub, .ant-menu-vertical.ant-menu-sub {
    min-width: 70px !important;
  }
  .userImg div{
    float: left;
    width: 100%;
    height: 100%;
  }
  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    text-align: center;
  }