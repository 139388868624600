.footerStyle{
  background-color: #373737;
  color: #91929A;
  font-size: 16px;
  padding: 0;
  float: left;
  width: 100%;
}
.leftText{
  float: left;
  line-height: 28px;
  font-size: 15px;
  height: 138px;
}
.rightText{
  float: right;
  height: 138px;
}
@media screen and (max-width: 1313px) {
  .rightText{
    float: left;
  }
}
.qrcodeContainer{
  float: right;
  margin-left: 30px;
  height: 138px;
}
.qrTitle{
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #E5E5E5;
  font-size: 20px;
}
.qrItemContainer{
  display: inline-block;
  width: 107px;
  margin: 0 15px;
}
.qrItemContainer img{
  width: 107px;
  height: 107px;
}
.topInfo{
  
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 138px;
  padding: 120px 218px 60px;
}
.bottomInfo{
  height: 50px;
  background: #2E2E2E;
  font-size: 13px;
  text-align: center;
  line-height: 50px;
}